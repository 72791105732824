<template>
  <div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { mutationsType } from "@/store/mutations-type.js";
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
import { ROUTER_PATH } from "@/constants/common.js";
export default {
  name: "LoginOut",
  mounted() {
    localStorage.setItem(LOCAL_STORAGE_KEY.RPA_TOKEN, "");
    localStorage.setItem(LOCAL_STORAGE_KEY.RPA_USER_ID, "");
    this[mutationsType.INIT_ACCOUNT_INFO]();
    this.$router.push(ROUTER_PATH.LOGIN);
  },
  methods: {
    ...mapMutations([mutationsType.INIT_ACCOUNT_INFO])
  }
};
</script>
